import React from 'react'

import { Link } from 'gatsby'

const Header = ({ darkMode, toggleDarkMode, layout }) => (
  <div className="navbar">
    {/* {layout === 'post' && ( */}
    <div className="flex-1">
      <Link to="/" aria-label="home" className="link link-primary mr-4 no-underline">
        dusanstam.com
      </Link>
      <Link to="/about" aria-label="about" className="link link-primary no-underline">
        About Me
      </Link>
    </div>
    {/* )} */}
    <button
      className="link link-primary no-underline"
      onClick={toggleDarkMode}
      aria-label="toggle theme"
    >
      {darkMode ? 'Light' : 'Dark'}
    </button>
  </div>
)

export default Header
