import React from 'react'

const Footer = () => (
  <div className="mx-auto my-5 flex w-full flex-col justify-between px-4 py-10 sm:flex-row">
    <a
      href="https://github.com/dstamenkovic"
      className="link link-primary my-2"
      target="_blank"
      rel="noreferrer noopener"
      aria-label="GitHub"
    >
      GitHub
    </a>

    <a
      href="https://www.linkedin.com/in/dusan-stamenkovic"
      target="_blank"
      className="link link-primary my-2"
      rel="noreferrer noopener"
      aria-label="LinkedIn Profile"
    >
      LinkedIn
    </a>

    <a
      href="https://www.youtube.com/@dusanstam"
      className="link link-primary my-2"
      target="_blank"
      rel="noreferrer noopener"
      aria-label="YouTube Channel"
    >
      YouTube
    </a>

    <a
      href="mailto:dusanstameni@gmail.com"
      className="link link-primary my-2"
      target="_blank"
      rel="noreferrer noopener"
      aria-label="Email"
    >
      dusanstameni@gmail.com
    </a>
  </div>
)

export default Footer
